import React, { createContext, useState, useContext } from 'react';

// Create a context for the wallet
const WalletContext = createContext();

// Create a provider component
export const WalletProvider = ({ children }) => {
    const [walletAddress, setWalletAddress] = useState(null);

    // Function to connect the wallet
    const connectWallet = async () => {
        try {
            const accounts = await window.unisat.requestAccounts();
            setWalletAddress(accounts[0]);
        } catch (error) {
            console.error('Failed to connect wallet:', error);
        }
    };

    // Function to disconnect the wallet
    const disconnectWallet = () => {
        setWalletAddress(null);  // Reset the wallet address to null to disconnect
        try {
            window.unisat.disconnect();  // Optional: If using an API that has a disconnect method
        } catch (error) {
            console.error('Failed to disconnect wallet:', error);
        }
    };

    return (
        <WalletContext.Provider value={{ walletAddress, connectWallet, disconnectWallet }}>
            {children}
        </WalletContext.Provider>
    );
};

// Custom hook to use the WalletContext
export const useWallet = () => useContext(WalletContext);
