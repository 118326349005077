import React, { useState, useEffect, useCallback } from 'react';
import { Table, message, Spin } from 'antd'; // Spin bileşeni eklendi
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoins, faCopy } from '@fortawesome/free-solid-svg-icons';
import './WalletInfo.css'; // CSS dosyasını import edin

const WalletInfo = () => {
  const [walletAddress, setWalletAddress] = useState('');
  const [inputAddress, setInputAddress] = useState('');
  const [cat20Tokens, setCat20Tokens] = useState([]);
  const [brc20Tokens, setBrc20Tokens] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null); // Error state eklendi

  // CAT20 tokenleri yükleme
  const loadCat20Tokens = useCallback(async () => {
    try {
      setLoading(true);
      const cat20Response = await axios.get(`https://cat20-track.btcterminal.io/api/addresses/${walletAddress}/balances`);
      const cat20Data = cat20Response.data;

      if (cat20Data.code === 0 && cat20Data.data && cat20Data.data.balances) {
        setCat20Tokens(cat20Data.data.balances);
      } else {
        setError('Failed to load CAT20 tokens');
      }
    } catch (error) {
      console.error('Error fetching CAT20 balances:', error);
      setError('Failed to load CAT20 tokens');
    } finally {
      setLoading(false);
    }
  }, [walletAddress]);

  // BRC20 tokenleri yükleme
  const loadBrc20Tokens = useCallback(async () => {
    try {
      setLoading(true);
      const token = '7675e39c2751a1f188596a13d884472cdaaaf275578ebfa56ac76e01f350a036';
      const url = `https://open-api-fractal.unisat.io/v1/indexer/address/${walletAddress}/brc20/summary?start=0&limit=16`;

      const response = await axios.get(url, {
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });

      const data = response.data.data?.detail;
      if (data) {
        const parsedTokens = data.map((token) => ({
          ticker: token.ticker,
          availableBalance: Number(token.availableBalance),
          transferableBalance: Number(token.transferableBalance),
          decimal: token.decimal,
        }));
        setBrc20Tokens(parsedTokens);
      } else {
        setError('No BRC20 tokens available.');
      }
    } catch (error) {
      console.error('Error fetching BRC20 tokens:', error);
      setError('Failed to load BRC20 tokens');
    } finally {
      setLoading(false);
    }
  }, [walletAddress]);

  useEffect(() => {
    if (walletAddress) {
      loadCat20Tokens();
      loadBrc20Tokens();
    }
  }, [walletAddress, loadCat20Tokens, loadBrc20Tokens]);

  const balanceColumns = [
    {
      title: 'Ticker',
      dataIndex: 'ticker',
      key: 'ticker',
    },
    {
      title: 'Available Balance',
      dataIndex: 'availableBalance',
      key: 'availableBalance',
      render: (balance) => balance?.toLocaleString() || 'N/A',
    },
    {
      title: 'Transferable Balance',
      dataIndex: 'transferableBalance',
      key: 'transferableBalance',
      render: (balance) => balance?.toLocaleString() || 'N/A',
    },
  ];

  const cat20Columns = [
    {
      title: 'Token Name',
      dataIndex: 'tokenInfo',
      key: 'name',
      render: (tokenInfo) => tokenInfo?.name || 'N/A',
    },
    {
      title: 'Symbol',
      dataIndex: 'tokenInfo',
      key: 'symbol',
      render: (tokenInfo) => tokenInfo?.symbol || 'N/A',
    },
    {
      title: 'Available Balance',
      dataIndex: 'confirmed',
      key: 'confirmed',
      render: (confirmed, record) => (confirmed / 10 ** record.tokenInfo.decimals).toLocaleString(),
    },
  ];

  const handleInputChange = (e) => {
    setInputAddress(e.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      setWalletAddress(inputAddress);
    }
  };

  return (
    <div className="wallet-info-container">
      <div className="input-container">
        <input
          type="text"
          placeholder="Enter wallet address"
          value={inputAddress}
          onChange={handleInputChange}
          onKeyPress={handleKeyPress}
        />
      </div>

      {error && <p className="error-message">{error}</p>}

      {/* Tabloları yan yana koyuyoruz */}
      <div className="token-tables">
        {/* CAT20 Token Table */}
        <div className="token-table">
          <h3>CAT20 Tokens</h3>
          {loading ? <Spin tip="Loading CAT20 tokens..." /> : (
            <Table
              dataSource={cat20Tokens.length > 0 ? cat20Tokens : []}
              columns={cat20Columns}
              pagination={{ pageSize: 6 }} // Sayfada en fazla 6 token gösterilecek
              rowKey={(record) => record.tokenInfo.symbol}
              locale={{ emptyText: 'No CAT20 tokens available' }}
            />
          )}
        </div>

        {/* BRC20 Token Table */}
        <div className="token-table">
          <h3>BRC20 Tokens</h3>
          {loading ? <Spin tip="Loading BRC20 tokens..." /> : (
            <Table
              dataSource={brc20Tokens.length > 0 ? brc20Tokens : []}
              columns={balanceColumns}
              pagination={{ pageSize: 6 }} // Sayfada en fazla 6 token gösterilecek
              rowKey="ticker"
              locale={{ emptyText: 'No BRC20 tokens available' }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default WalletInfo;
