import React from 'react';
import "./Footer.css"

function Footer() {
    return (
        <footer className="footer">
            <div className="footer-content">
                <div className="footer-social">
                    <a href="https://x.com/fractaltigerclb" target="_blank" rel="noopener noreferrer">
                        <img src="icons/x.png" alt="Twitter" />
                    </a>
                    <a href="https://fractal-tiger-club.gitbook.io/fractal-tiger-club" target="_blank" rel="noopener noreferrer">
                        <img src="icons/gitbook.png" alt="GitHub" />
                    </a>
                    <a href="https://discord.gg/rzmbQFX" target="_blank" rel="noopener noreferrer">
                        <img src="icons/discord.png" alt="Telegram" />
                    </a>
                </div>
            </div>
            <div className="footer-bottom">
                <p>&copy; 2024 Fractal Tiger Club. All Rights Reserved.</p>
            </div>
        </footer>
    );
}

export default Footer;
