import React, { useState } from 'react';
import { useWallet } from '../components/context/WalletContext'; 
import './WalletChecker.css'; 

function WalletChecker() {
    const { walletAddress, connectWallet } = useWallet();
    const [isEligible, setIsEligible] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const eligibleInscriptionNumbers = [
        3409057,
        123456,
        789012,
        15114898,
    ];

    const truncateAddress = (address) => {
        if (!address) return '';
        return `${address.slice(0, 4)}...${address.slice(-4)}`;
    };

    const checkEligibility = async () => {
        setLoading(true);
        setError(null);
        setIsEligible(null);

        try {
            const response = await fetch(`https://open-api-fractal.unisat.io/v1/indexer/address/${walletAddress}/inscription-data?cursor=0&size=16`, {
                method: 'GET',
                headers: {
                    'accept': 'application/json',
                    'Authorization': 'Bearer 2fc16c884a076b6b17ea5f2c5e2dca0aff09a6d29540a249ecd68ecec30289d3',
                },
            });

            if (!response.ok) {
                throw new Error('Failed to fetch data');
            }

            const data = await response.json();

            const hasEligibleInscription = data.data.inscription.some(inscription =>
                eligibleInscriptionNumbers.includes(inscription.inscriptionNumber)
            );

            setIsEligible(hasEligibleInscription);
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="wallet-checker-container">
            <h2>Wallet Checker</h2>

            {walletAddress ? (
                <>
                    <p className="wallet-address">Connected Wallet: {truncateAddress(walletAddress)}</p>
                    <button className="check-button" onClick={checkEligibility}>
                        Check Eligibility
                    </button>
                    {loading && <p className="loading-text">Loading...</p>}
                    {error && <p className="error-text">Error: {error}</p>}
                    {isEligible !== null && (
                        <p className={isEligible ? "eligible-text" : "not-eligible-text"}>
                            {isEligible ? 'This wallet is eligible for mint!' : 'This wallet is not eligible for mint.'}
                        </p>
                    )}
                </>
            ) : (
                <>
                    <p className="connect-message">Please connect your wallet to check eligibility.</p>
                    <button className="connect-button" onClick={connectWallet}>
                        Connect Wallet
                    </button>
                </>
            )}
        </div>
    );
}

export default WalletChecker;
