import React, { useEffect, useState } from 'react';
import './CircularSlider.css'; // CSS stil dosyasını import edin

const CircularSlider = ({ nfts }) => {
    const [position, setPosition] = useState(0); // Yatayda kaydırma pozisyonu
    const itemWidth = 150; // Her bir NFT'nin genişliği (150px)
    const slideSpeed = 1; // Kaydırma hızını ayarlıyoruz

    // Yavaş yavaş kaydırma işlemi
    useEffect(() => {
        const interval = setInterval(() => {
            setPosition((prevPosition) => {
                if (prevPosition <= -itemWidth * nfts.length) {
                    // Eğer tüm NFT'ler kaydıysa başa döner (sonsuz döngü)
                    return 0;
                }
                return prevPosition - slideSpeed; // Yavaş kaydırma
            });
        }, 16); // Yaklaşık 60 FPS
        return () => clearInterval(interval);
    }, [nfts.length]);

    return (
        <div className="slider-container">
            <div
                className="slider-track"
                style={{ transform: `translateX(${position}px)` }}
            >
                {nfts.concat(nfts).map((nft, index) => ( // NFT'leri sonsuz döngü için iki kez renderlıyoruz
                    <div key={index} className="nft-item">
                        <img src={nft.image} alt={`NFT ${index}`} />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default CircularSlider;
