import React from 'react';
import WalletInfo from './WalletInfo';

const WalletPage = () => {
    return (
        <div 
            style={{
                display: 'flex',            // Flexbox aktif et
                justifyContent: 'center',    // Yatayda ortala
                alignItems: 'center',        // Dikeyde ortala
                height: '100vh',             // Yüksekliği tüm pencereye yayılsın
                padding: '20px',
                flexDirection: 'column',     // İçerikleri dikey hizala
            }}
        >
            <h2 style={{ color: '#ff8c00', marginBottom: '20px' }}>Wallet Information</h2>
            <WalletInfo />
        </div>
    );
};

export default WalletPage;
