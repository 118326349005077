import React from 'react';
import './Launchpad.css'; // CSS dosyasını import edin

const Launchpad = () => {
    return (
        <div className="launchpad-container">
            <div className="video-background">
                <video src="/assets/running_tiger.mp4" autoPlay loop muted playsInline />
            </div>
            <div className="content-overlay">
                <h1 className="animated-text">COMING SOON</h1>
            </div>
        </div>
    );
};

export default Launchpad;
