import React, { useEffect, useState } from 'react';
import './Hero.css'; // Custom styles
import CircularSlider from './CircularSlider';


const nftData = [
  { image: 'assets/tiger1.jpeg' },
  { image: 'assets/tiger2.jpeg' },
  { image: 'assets/tiger3.jpeg' },
  { image: 'assets/tiger4.png' },
  { image: 'assets/tiger5.png' },
  { image: 'assets/tiger6.png' },
  { image: 'assets/tiger7.png' },
  { image: 'assets/tiger8.png' },
  { image: 'assets/tiger9.png' },
  { image: 'assets/tiger10.png' },
  { image: 'assets/tiger11.png' },
  { image: 'assets/tiger12.png' },
  { image: 'assets/tiger13.png' },
  { image: 'assets/tiger14.png' },
  { image: 'assets/tiger15.png' },
  { image: 'assets/tiger16.png' },
  { image: 'assets/tiger17.png' },
  { image: 'assets/tiger18.png' },
  { image: 'assets/tiger19.png' },
  { image: 'assets/tiger20.png' },
  { image: 'assets/tiger21.png' },
  { image: 'assets/tiger22.png' },
  { image: 'assets/tiger23.png' },
  { image: 'assets/tiger24.png' },
  { image: 'assets/tiger25.png' },
  { image: 'assets/tiger26.png' },
  { image: 'assets/tiger27.png' },
  { image: 'assets/tiger28.png' }
];



const faqData = [
  {
    question: "How will the Fractal Tiger Club NFTs be distributed?",
    answer: "The Fractal Tiger Club NFTs will find their owners through a fair minting process. This ensures that every participant has an equal chance to mint these unique NFTs, without any favoritism or unfair advantages. The fair mint process is designed to create a balanced and transparent distribution, allowing both seasoned collectors and newcomers to participate equally."
  },
  {
    question: "Who created the Fractal Tiger Club?",
    answer: "The Fractal Tiger Club is the brainchild of a collective of Bitcoin Ordinal OGs, combining their passion for decentralized technologies with a desire to explore new creative horizons. The vibrant and imaginative artwork was crafted by an enigmatic digital artist, whose identity remains a mystery. This artist is known for blending humor with vivid visuals, creating a collection that captures the wild energy of the blockchain and the unpredictability of the digital world."
  },
  {
    question: "What can I do with the Fractal Tiger Club NFTs I own?",
    answer: "Owning a Fractal Tiger Club NFT offers both digital and real-world opportunities. In the digital realm, you can trade them in NFT marketplaces, showcase them in virtual galleries, or integrate them into your online identity. Additionally, on the official website, fractaltiger.club, NFT holders will have exclusive benefits in future events, such as priority access to minting and swapping features. Beyond the digital space, there will be real-world applications for Fractal Tiger Club NFTs, including access to special gatherings, events, and collaborations with other brands. NFT holders will enjoy VIP perks and priority invitations to upcoming physical and virtual experiences, making ownership not just an investment, but a lifestyle."
  },
  {
    question: "What’s the future plan for the Fractal Tiger Club?",
    answer: "The Fractal Tiger Club is not just another NFT collection—it’s an ever-evolving journey. With plans to venture into the depths of Decentralized Finance (DeFi) and explore uncharted territories in the metaverse, the Fractal Tigers are on a mission to redefine the digital frontier. As the community expands, so too will the opportunities for discovery, innovation, and adventure. NFT holders are not just spectators but pioneers, invited to actively participate in shaping the future of the project."
  },
];

const Hero = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const totalPages = 3;
  const [isScrolling, setIsScrolling] = useState(false);
  const [openFaqIndex, setOpenFaqIndex] = useState(null); // Hangi FAQ açık olduğunu izler

  // Kaydırma fonksiyonu
  const handleScroll = (event) => {
    if (!isScrolling) {
      const { deltaY } = event;
      setIsScrolling(true);
      if (deltaY > 0) {
        setCurrentPage((prevPage) => (prevPage < totalPages - 1 ? prevPage + 1 : prevPage));
      } else {
        setCurrentPage((prevPage) => (prevPage > 0 ? prevPage - 1 : prevPage));
      }
      setTimeout(() => {
        setIsScrolling(false);
      }, 1000);
    }
  };

  useEffect(() => {
    window.addEventListener('wheel', handleScroll);

    return () => {
      window.removeEventListener('wheel', handleScroll);
    };
  }, [isScrolling]);

  // FAQ butonuna tıklama işlevi
  const toggleFaq = (index) => {
    setOpenFaqIndex(openFaqIndex === index ? null : index); // Aynı soruya tıklanırsa kapat, değilse aç
  };


  return (
    <div className="hero-container">
      <div
        className="pages-wrapper"
        style={{
          transform: `translateY(-${currentPage * 100}vh)`,
          transition: 'transform 0.8s ease-in-out',
        }}
      >
        <section className="page page1">
          <div className="table-container">
            <img src="/assets/tigergif.gif" alt="gif" className="gif-content" />
          </div>
          <div className="content">
            <CircularSlider nfts={nftData} />
          </div>
        </section>

        <section className="page page2">
          <div className="content">
          </div>
        </section>

        <section className="page page3">
          <div className="content">
            <h2>FAQ - Frequently Asked Questions</h2>
            <div className="faq-container">
              {faqData.map((faq, index) => (
                <div key={index} className="faq-item">
                  <button className="faq-button" onClick={() => toggleFaq(index)}>
                    {faq.question}
                    <span className={`faq-icon ${openFaqIndex === index ? 'open' : ''}`}>▼</span>
                  </button>
                  <div className={`faq-content ${openFaqIndex === index ? 'open' : ''}`}>
                    <p>{faq.answer}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      </div>


      {/* Sağdaki Noktalar */}
      <div className="scroll-dots">
        <div className={`dot ${currentPage === 0 ? 'active' : ''}`}></div>
        <div className={`dot ${currentPage === 1 ? 'active' : ''}`}></div>
        <div className={`dot ${currentPage === 2 ? 'active' : ''}`}></div>
      </div>
    </div>
  );
};

export default Hero;

