import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBullseye, faCheck, faWallet, faSignOutAlt, faCoins, faCopy, faCaretDown, faRocket } from '@fortawesome/free-solid-svg-icons';
import { useWallet } from './context/WalletContext';
import './Header.css';

function Header() {
    const location = useLocation();
    const { walletAddress, connectWallet, disconnectWallet } = useWallet();
    const [showWalletInfo, setShowWalletInfo] = useState(false);
    const [balance, setBalance] = useState({
        confirmed: 0,
        unconfirmed: 0,
        total: 0,
    });

    useEffect(() => {
        const getWalletBalance = async () => {
            if (walletAddress) {
                try {
                    const balance = await window.unisat.getBalance();
                    setBalance(balance);
                } catch (e) {
                    console.error("Error fetching balance:", e);
                }
            }
        };
        getWalletBalance();
    }, [walletAddress]);

    const formatBTC = (satoshis) => {
        return (satoshis / 100000000).toFixed(8);
    };

    const truncateAddress = (address) => {
        if (!address) return '';
        return `${address.slice(0, 4)}...${address.slice(-4)}`;
    };

    const copyToClipboard = () => {
        navigator.clipboard.writeText(walletAddress);
        alert('Wallet address copied to clipboard!');
    };

    return (
        <>
            <header style={{ display: 'flex', alignItems: 'center', padding: '10px 20px' }}>
                <Link to="/" className={`nav-item1 ${location.pathname === '/' ? 'active' : ''}`}>
                    <img 
                        src="/assets/tigerlogo.png" 
                        alt="Fractal Tiger Club Logo" 
                        style={{ width: 'auto', height: '40px', margin: 0 }} 
                        onselectstart="return false"
                    />
                </Link>
                <div className="divider"></div>
                <Link to="/wallet" className={`nav-item ${location.pathname === '/wallet' ? 'active' : ''}`}>
                    <FontAwesomeIcon icon={faWallet} />
                    <span style={{ marginLeft: '8px' }}> Balances</span>
                </Link>
                <div className="divider"></div>
                <Link to="/cat-tracker" className={`nav-item ${location.pathname === '/cat-tracker' ? 'active' : ''}`}>
                    <FontAwesomeIcon icon={faBullseye} />
                    <span style={{ marginLeft: '8px' }}> CAT20-Tracker</span>
                </Link>
                <div className="divider"></div>


                <Link to="/checker" className={`nav-item ${location.pathname === '/checker' ? 'active' : ''}`}>
                    <FontAwesomeIcon icon={faCheck} />
                    <span style={{ marginLeft: '8px' }}> Wallet Checker</span>
                </Link>
                <div className="divider"></div>

                <Link to="/cat20" className={`nav-item ${location.pathname === '/cat20' ? 'active' : ''}`}>
                    <FontAwesomeIcon icon={faRocket} />
                    <span style={{ marginLeft: '8px' }}> CAT-20</span>
                </Link>

                {/* Wallet Connection Section */}
                <div style={{ marginLeft: 'auto', position: 'relative' }}>
                    {walletAddress ? (
                        <div>
                            <div 
                                onClick={() => setShowWalletInfo(!showWalletInfo)} 
                                style={walletTextStyle}>
                                <span>{truncateAddress(walletAddress)}</span>
                                <FontAwesomeIcon icon={faCaretDown} style={{ marginLeft: '8px' }} />
                            </div>

                            {/* Wallet Info Modal/Dropdown */}
                            <div 
                                style={{
                                    opacity: showWalletInfo ? 1 : 0,
                                    transform: showWalletInfo ? 'translateY(0)' : 'translateY(-20px)',
                                    transition: 'opacity 0.3s ease, transform 0.3s ease',
                                    background: '#1e1e1e',
                                    border: '1px solid #333',
                                    borderRadius: '10px',
                                    padding: showWalletInfo ? '10px 15px' : '0',
                                    boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
                                    zIndex: 1,
                                    width: '230px',
                                    color: '#fff',
                                    position: 'absolute',
                                    top: '50px',
                                    right: '0',
                                }}>
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '15px', cursor: 'pointer' }}>
                                    <FontAwesomeIcon 
                                        icon={faCopy} 
                                        style={{ marginRight: '10px', color: '#fff' }} 
                                        onClick={copyToClipboard}
                                        title="Copy to clipboard"
                                    />
                                    <p style={{ margin: 0, fontWeight: 'bold', flex: 1 }}>
                                        Address: {truncateAddress(walletAddress)}
                                    </p>
                                </div>

                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px', cursor: 'pointer' }}>
                                    <FontAwesomeIcon icon={faCoins} style={{ marginRight: '10px', color: '#fff' }} />
                                    <p style={{ margin: 0, fontWeight: 'bold', flex: 1 }}>{formatBTC(balance.total)} FB</p>
                                </div>

                                {/* Divider Line */}
                                <div style={{
                                    width: '100%',
                                    height: '1px',
                                    backgroundColor: '#333',
                                    margin: '10px 0'
                                }}></div>

                                {/* Disconnect Section */}
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', cursor: 'pointer' }} onClick={disconnectWallet}>
                                    <FontAwesomeIcon icon={faSignOutAlt} style={{ marginRight: '10px', color: '#ff4d4d' }} />
                                    <p style={{ margin: 0, fontWeight: 'bold', color: '#ff4d4d', flex: 1 }}>Disconnect</p>
                                </div>

                            </div>

                        </div>
                    ) : (
                        <div 
                            onClick={connectWallet} 
                            style={walletTextStyle}>
                            Connect Wallet <FontAwesomeIcon icon={faCaretDown} style={{ marginLeft: '8px' }} />
                        </div>
                    )}
                </div>
            </header>
        </>
    );
}

// Styles for Wallet Connection text
const walletTextStyle = {
    padding: '8px 12px', 
    color: '#ff8c00', 
    border: 'none', 
    cursor: 'pointer', 
    fontWeight: '500',
    display: 'inline-block',
    textDecoration: 'none',
    position: 'relative',
    transition: 'all 0.3s ease',
    borderBottom: '2px solid transparent',
};

// getLinkStyle function to handle active and hover states
const getLinkStyle = (isActive) => ({
    display: 'flex',
    alignItems: 'center',
    fontSize: '16px',
    fontWeight: '500',
    color: isActive ? '#ff8c00' : '#e3e1e1',
    textDecoration: isActive ? 'underline' : 'none',
    textUnderlineOffset: '4px',
    textDecorationThickness: isActive ? '2px' : '0',
    textDecorationColor: isActive ? '#ff8c00' : 'transparent',
    transition: 'color 0.3s, text-decoration-color 0.3s',
});

export default Header;
