import React, { useState, useEffect } from 'react';
import { Table, Spin, Pagination } from 'antd';
import axios from 'axios';
import { Treemap, Tooltip, ResponsiveContainer } from 'recharts';
import './Cat-Tracker.css';

const CatTracker = () => {
  const [mintData, setMintData] = useState([]);
  const [allData, setAllData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 6;
  const maxPages = 5; 

  // API'den mint verilerini çekme
  const fetchMintData = async () => {
    try {
      const response = await axios.get('https://cat-monitor.com/api/mint-data');
      const sortedData = response.data.sort((a, b) => b.count - a.count); // Mint count'a göre sıralama
      setMintData(sortedData);
    } catch (error) {
      console.error('Error fetching mint data:', error);
    }
  };

  // API'den tüm işlemleri çekme ve blockHeight'e göre sıralama
  const fetchAllData = async () => {
    try {
      const response = await axios.get('https://cat-monitor.com/api/all-data');
      const sortedAllData = response.data.sort((a, b) => b.blockHeight - a.blockHeight); // Block ID'ye göre sıralama (büyükten küçüğe)
      setAllData(sortedAllData);
    } catch (error) {
      console.error('Error fetching all data:', error);
    } finally {
      setLoading(false);
    }
  };

  // Sayfa yüklendiğinde ve her 30 saniyede bir API verilerini güncelle
  useEffect(() => {
    fetchMintData();
    fetchAllData();
    const intervalId = setInterval(() => {
      fetchMintData();
      fetchAllData();
    }, 30000); // 30 saniyede bir günceller
    return () => clearInterval(intervalId); // Bileşen unmounted olduğunda interval temizlenir
  }, []);

  // Treemap verilerini hazırlama
  const treemapData = mintData.map(item => ({
    name: `${item.name} (${item.symbol})`,
    size: item.count, // 'size' anahtarı blok büyüklüğünü belirler
    count: item.count, // Tooltipte göstermek için token miktarını kaydettik
  }));

  // Sayfa başına gösterilecek işlem verilerini ayarlama
  const paginatedData = allData.slice((currentPage - 1) * pageSize, currentPage * pageSize);

  // Sayfa değiştirme işlemi
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  // All Data işlemleri için tablo sütunları
  const columns = [
    {
      title: 'Block #',
      dataIndex: 'blockHeight',
      key: 'blockHeight',
    },
    {
      title: 'TX ID',
      dataIndex: 'txid',
      key: 'txid',
      render: txid => (
        <a href={`https://mempool.fractalbitcoin.io/tx/${txid}`} target="_blank" rel="noopener noreferrer">
          {`${txid.slice(0, 6)}...${txid.slice(-6)}`}
        </a>
      ),
    },
    {
      title: 'Type',
      dataIndex: 'txType',
      key: 'txType',
      render: type => (
        <span style={{ color: type === 'mint' ? 'green' : type === 'reveal' ? 'orange' : 'blue' }}>
          {type.charAt(0).toUpperCase() + type.slice(1)}
        </span>
      ),
    },
    {
      title: 'Token',
      dataIndex: 'tokenSymbol',
      key: 'tokenSymbol',
      render: (symbol) => symbol || 'N/A',
    },
    {
      title: 'Amount',
      dataIndex: 'tokenAmount',
      key: 'tokenAmount',
      render: (amount) => amount ? (amount / 100).toLocaleString() : 'N/A', // Token amountu /100 ile bölerek göster
    },
  ];

  return (
    <div className="dashboard-container">
      <h1>CAT20 Mint Transactions in Mempool</h1>
      <div className="content">
        <div className="treemap-section">
          {loading ? (
            <Spin size="large" />
          ) : (
            <ResponsiveContainer width="100%" height={500}>
              <Treemap
                data={treemapData}
                dataKey="size"
                nameKey="name"
                stroke="#fff"
                fill="#82ca9d"
                isAnimationActive={true}
                contentStyle={{ borderRadius: '10px', padding: '10px' }}
                aspectRatio={4 / 3}
              >
                <Tooltip
                  formatter={(value, name, props) => {
                    return [`Mint Count: ${props.payload.count}`, name];
                  }}
                />
              </Treemap>
            </ResponsiveContainer>
          )}
        </div>
        <div className="table-section">
          <h2>Recent CAT Operations</h2>
          <Table
            dataSource={paginatedData}
            columns={columns}
            rowKey="txid"
            pagination={false}
            loading={loading}
            bordered
          />
          {/* Pagination for 5 pages */}
          <Pagination
            current={currentPage}
            pageSize={pageSize}
            total={pageSize * maxPages} // 5 sayfa için toplamda 30 işlem
            onChange={handlePageChange}
            style={{ marginTop: '20px', textAlign: 'center' }}
          />
        </div>
      </div>
    </div>
  );
};

export default CatTracker;
