import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Hero from './components/Hero';
import WalletPage from './components/WalletPage';
import WalletChecker from './components/WalletChecker';
import Launchpad from './components/Launchpad';

import './App.css';
import { WalletProvider } from './components/context/WalletContext';
import CatTracker from './components/Cat-Tracker';
import { Analytics } from "@vercel/analytics/react"

function App() {
  return (
      <WalletProvider>
          <Router>
               <Header />
              <main>
                    <Routes>
                      <Route path="/" element={<Hero />} />
                      <Route path="/wallet" element={<WalletPage />} />
                      <Route path="/checker" element={<WalletChecker/>}/>            
                      <Route path="/cat20" element={<Launchpad/>}/>  
                      <Route path="/cat-tracker" element={<CatTracker/>}/>          

                    </Routes>
              </main>
              <Footer />
          </Router>
      </WalletProvider>
  );
}

export default App;
